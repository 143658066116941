import { useMemo, useCallback, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';
import { ThemeColors } from 'styles/theme';
import { useTracker } from 'hooks/use-tracker';
import { ClinicArea } from 'domain/clinic-overview';
import { routes } from 'modules/routes/routes-config';
import { getRgbaStrFromHexColor } from 'styles/utils/color';
import { HotspotModel, HotspotPositionCSS } from 'domain/hotspot';
import { Hotspot, HotspotButton } from 'modules/app/shared/hotspot/hotspot';
import { useClinicOverviewContext } from 'modules/context/clinic-overview';
import { useTranslation } from 'react-i18next';
import { getCountryFromUrl } from 'utils/url-utils';
import { useSelector } from 'react-redux';

type ClinicHotspotWrapperProps = {
    index: number;
    color: keyof ThemeColors['navigation'];
};

const ClinicHotspotWrapper = styled.div<ClinicHotspotWrapperProps>`
    ${({
        theme: {
            base: { colors, zindex }
        },
        index,
        color
    }) => `
        animation: show 0.5s;
        animation-delay: ${index / 3}s;
        animation-fill-mode: forwards;
        position: absolute;
        visibility: hidden;

        @keyframes show {
            99% { visibility: hidden; }
            100% { visibility: visible; }
        }

        &:hover {
            z-index: ${zindex.above};
        }

        ${HotspotButton} {
            &, &:active {
                background-color: ${colors.navigation[color]};
                box-shadow: 0 0 0 ${rem(6)} ${getRgbaStrFromHexColor(
        colors.navigation[color],
        0.3
    )};
                color: ${colors.neutral.fullLight};
            }

            &:hover {
                box-shadow: 0 0 0 ${rem(6)} ${getRgbaStrFromHexColor(
        colors.navigation[color],
        0.4
    )};
            }
        }
    `}
`;

export type ClinicHotspotsLayerProps = {
    area: ClinicArea | null;
};

const ClinicHotspotsLayer = ({ area }: ClinicHotspotsLayerProps) => {
    const { t } = useTranslation();
    const { state } = useClinicOverviewContext();
    const history = useHistory();
    const { setDataForTracker } = useTracker();
    const supportedCountries: string[] = useSelector((state: any) => {
        return state.app.supportedCountries;
    });
    const country = getCountryFromUrl();

    const hotspots = useMemo(() => {
        return area?.paintingInfo
            .flatMap((info) => info.hotspots ?? [])
            .map((hotspot) => ({
                hotspot,
                topic: state.topics.find((t) => t.name === hotspot.roomId)
            }))
            .filter((x) => x.hotspot && x.topic)
            .map((x) => ({
                ...x.hotspot,
                title: x.topic!.nameLanguage
            }));
    }, [area, state.topics]);

    const navigateToRoom = useCallback(
        ({ areaId, roomId }: HotspotModel<HotspotPositionCSS>) => {
            history.push(
                routes.room.getRoute(
                    areaId,
                    roomId,
                    country,
                    supportedCountries
                )
            );
        },
        []
    );

    return area && hotspots ? (
        <>
            {hotspots.map((hotspot, index) => (
                <ClinicHotspotWrapper
                    key={hotspot.roomId}
                    index={index}
                    color={area.color}
                    style={hotspot.position}
                >
                    <Hotspot
                        {...setDataForTracker({
                            category: 'hotspot',
                            action: `${hotspot.areaId}-${hotspot.roomId}`
                        })}
                        title={hotspot.title}
                        onClick={(event: MouseEvent<HTMLElement>) => {
                            navigateToRoom(hotspot);
                            event.stopPropagation();
                        }}
                        hotspotStyle='default'
                    />
                </ClinicHotspotWrapper>
            ))}
        </>
    ) : null;
};

export { ClinicHotspotsLayer };
