import { useState, useContext } from 'react';
import { ClinicOverviewContext } from 'modules/context/clinic-overview';
import { useTracker } from 'hooks/use-tracker';
import { Hotspot, HotspotWrapper } from './shared/hotspot/hotspot';
import { DefaultModal } from './shared/modal/default/default-modal';
import { Video } from './shared/video/video';
import { routes } from 'modules/routes/routes-config';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ModalVideo } from './shared/modal/templates/modal-video';
import { VirtualClinicAdvice } from './shared/advice/advice';
import { getCountryFromUrl } from 'utils/url-utils';
import { useSelector } from 'react-redux';
import { HomeWrapper } from './HomeWrapper';

const homeHostpots = {
    title: 'generics.title',
    description: 'generics.clickHereToEnter',
    position: { left: '32%', top: '45%' }
};

const Home = () => {
    const { dispatch: clinicOverviewDispatch } = useContext(
        ClinicOverviewContext
    );
    const supportedCountries: string[] = useSelector((state: any) => {
        return state.app.supportedCountries;
    });
    const { t } = useTranslation();
    const history = useHistory();
    const { setDataForTracker } = useTracker();
    const [showHotspot, setShowHotspot] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const country = getCountryFromUrl();

    return (
        <>
            {showModal && (
                <DefaultModal
                    bgStyle='dark'
                    modalSize='medium'
                    isBgBlur={true}
                    onClose={() => {}}
                >
                    <ModalVideo
                        videoSrc='https://vclincprodams-euwe.streaming.media.azure.net/574d88c3-e0ce-4cd7-ab84-55ce28b9c7f1/211110_Dr_Maddux_Virtual_Clinic_.ism/manifest(format=mpd-time-cmaf)'
                        title={t('areas.home.homeModal.content.title')}
                        description={t(
                            'areas.home.homeModal.content.description'
                        )}
                        btnLabel={t('areas.home.modal.btnLabel')}
                        onClick={() =>
                            history.push(
                                routes.doctorOffice.getRoute(
                                    'doctorOffice',
                                    country,
                                    supportedCountries
                                )
                            )
                        }
                    />
                </DefaultModal>
            )}
            <HomeWrapper>
                {showHotspot ? (
                    <>
                        <HotspotWrapper style={homeHostpots.position}>
                            <Hotspot
                                {...setDataForTracker({
                                    category: 'hotspot',
                                    action: 'home'
                                })}
                                title={t(homeHostpots.title)}
                                description={t(homeHostpots.description)}
                                onClick={() => setShowModal(true)}
                            />
                        </HotspotWrapper>
                    </>
                ) : null}

                <VirtualClinicAdvice fixedStyle />
                {country !== 'us' && (
                    <Video
                        src='/static/videos/intro-video.mp4'
                        onEndVideo={() => {
                            clinicOverviewDispatch({
                                type: 'OPEN_DISCLAIMER_MODAL'
                            });
                            setShowHotspot(true);
                        }}
                    />
                )}
            </HomeWrapper>
        </>
    );
};

export { Home };
