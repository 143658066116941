import React, { PropsWithChildren } from 'react';
import { BgModalStyle, Modal, ModalSize } from './../modal';
import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';
import { ButtonIcon, ButtonIconBase } from '../../button-icon/button-icon';

export type OriginModal = 'top' | 'bottom' | 'left';

export type DefaultModalProps = {
    bgStyle?: BgModalStyle;
    modalSize?: ModalSize;
    originModal?: OriginModal;
    isBgBlur?: boolean;
    showCloseBtn?: boolean;
    onClose: (event: React.MouseEvent, confirm: boolean) => void;
};

const SPACING_WRAPPER = 32;

const ModalContentWrapper = styled.div<{ showCloseBtn?: boolean }>`
    ${({
        theme: {
            base: { colors, zindex }
        },
        showCloseBtn
    }) => `
        background-color: ${colors.neutral.fullLight};
        box-sizing: border-box;
        max-height: calc(100% - ${rem(SPACING_WRAPPER * 3)});
        height: max-content;
        overflow-x: hidden;
        overflow-y: auto;
        opacity: 1;
        position: fixed;
        top: 50%;
        width: calc(100vw - ${rem(SPACING_WRAPPER * 2)});
        z-index: ${zindex.modalContent};

        &.small {
            max-width: ${rem(700)};
        }

        &.medium {
            max-width: ${rem(1150)};
        }

        &.fullWidth {
            max-width: 100%;
        }

        &.top {
            animation: showContent 3s, originTop 1.5s;
            left: 50%;
            transform: translate(-50%, -50%);

            @keyframes originTop {
                0%, 20% { transform: translate(-50%, -100%); }
                100% { transform: translate(-50%, -50%); }
            }
        }

        &.bottom {
            animation: showContent 3s, originBottom 1.5s;
            left: 50%;
            transform: translate(-50%, -50%);

            @keyframes originBottom {
                0%, 20% { transform: translate(-50%, 100%); }
                100% { transform: translate(-50%, -50%); }
            }
        }

        &.left {
            animation: showContent 1s, originLeft 1s;
            left: 0;
            transform: translate(0, -50%);

            @keyframes originLeft {
                0% { transform: translate(-100%, -50%); }
                100% { transform: translate(0, -50%); }
            }
        }

        @keyframes showContent {
            0% { opacity: 0; }
            100% { opacity: 1; }
        }

        ${
            showCloseBtn &&
            `
            ${ButtonIconBase} {
                position: absolute;
                right: 0;
                top: 0;
                z-index: ${zindex.XL};
            }`
        }
    `}
`;

const ModalContent = styled.section`
    margin: 0 auto;
    width: 100%;
`;

const DefaultModal: React.FC<DefaultModalProps> = ({
    children,
    bgStyle,
    modalSize = 'medium',
    originModal = 'bottom',
    isBgBlur,
    showCloseBtn,
    onClose
}: PropsWithChildren<DefaultModalProps>) => (
    <Modal
        onClose={(event) => onClose(event, false)}
        bgStyle={bgStyle}
        isBgBlur={isBgBlur}
    >
        <ModalContentWrapper
            className={modalSize ? modalSize + ' ' + originModal : originModal}
            showCloseBtn={showCloseBtn}
        >
            <ModalContent>
                {showCloseBtn && (
                    <ButtonIcon
                        iconName='cross'
                        onClick={(event) => onClose(event, false)}
                    />
                )}
                {children}
            </ModalContent>
        </ModalContentWrapper>
    </Modal>
);

export { DefaultModal };
